(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-get-all-participant-names-by-wager-id.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-get-all-participant-names-by-wager-id.js');
"use strict";


const {
  useGetAllParticipantNamesByDrawId
} = svs.components.tipsen.hooks;
const {
  useGetWagerDraw
} = svs.components.tipsen.hooksWager;
const useGetAllParticipantNamesByWagerId = _ref => {
  let {
    wagerId
  } = _ref;
  const {
    draw
  } = useGetWagerDraw({
    wagerId
  });
  const {
    productId,
    drawNumber
  } = draw || {};
  const participantNames = useGetAllParticipantNamesByDrawId("".concat(productId, "_").concat(drawNumber));
  return participantNames;
};
setGlobal('svs.components.tipsen.hooksWager.useGetAllParticipantNamesByWagerId', useGetAllParticipantNamesByWagerId);

 })(window);
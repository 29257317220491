(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-draw-forecast.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-draw-forecast.js');
"use strict";

const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  drawStateEnums
} = svs.components.sport.tipsenShared;
const {
  hooksWager
} = svs.components.tipsen;
const useDrawForecast = _ref => {
  let {
    productId,
    drawNumber,
    skip = false
  } = _ref;
  const drawState = hooksWager.useGetDrawState(productId, drawNumber);
  const shouldStartDrawForecastSubscription = drawState === drawStateEnums.LIVE && !skip;
  const shouldFetchDrawForecast = drawState === drawStateEnums.FINALIZED && !shouldStartDrawForecastSubscription && !skip;
  const drawForecast = tipsenApi.useGetDrawForecastQuery({
    drawNumber,
    productId
  }, {
    skip: !drawNumber || !productId || !shouldStartDrawForecastSubscription
  });
  const fetchedDrawForecast = tipsenApi.useGetDrawForecastFetchQuery({
    drawNumber,
    productId
  }, {
    skip: !drawNumber || !productId || !shouldFetchDrawForecast
  });
  return shouldFetchDrawForecast ? fetchedDrawForecast : drawForecast;
};
setGlobal('svs.components.tipsen.hooksWager.useDrawForecast', useDrawForecast);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-wager-current-correct-values.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-wager-current-correct-values.js');
"use strict";

const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const {
  MatchCorrectStatus,
  MatchResultTypes
} = svs.components.sport.tipsenShared.constants;
const isPossibleWinStatus = _ref => {
  let {
    playerOutcomes,
    currentOutcome
  } = _ref;
  const lastOutcome = playerOutcomes[playerOutcomes.length - 1];
  const canStillWin = currentOutcome < lastOutcome;
  return canStillWin ? MatchCorrectStatus.POSSIBLE_WIN : MatchCorrectStatus.NOT_CORRECT;
};
const useWagerCurrentCorrectValues = _ref2 => {
  let {
    userRow,
    drawResult,
    bestBetRowResult
  } = _ref2;
  if (!userRow || userRow.length === 0) {
    return null;
  }
  let correctStatus;
  if (drawResult && Object.keys(drawResult).length > 0) {
    const isNotStarted = drawResult.matchResultState === MatchResultTypes.NOT_ENTERED;
    if (isNotStarted) {
      return null;
    }
    const isFinished = drawResult.matchResultState === MatchResultTypes.VERIFIED;
    if (bestBetRowResult) {
      if (isFinished) {
        correctStatus = bestBetRowResult.includes(drawResult.outcome[0][0]) ? MatchCorrectStatus.CORRECT : MatchCorrectStatus.NOT_CORRECT;
      } else {
        correctStatus = bestBetRowResult.includes(drawResult.outcome[0][0]) ? MatchCorrectStatus.CORRECT : isPossibleWinStatus({
          playerOutcomes: bestBetRowResult,
          currentOutcome: drawResult.outcome[0][0]
        });
      }
    } else if (isFinished) {
      correctStatus = userRow.includes(drawResult.outcome[0][0]) ? MatchCorrectStatus.CORRECT : MatchCorrectStatus.NOT_CORRECT;
    } else if (drawResult.eventTypeId === EventTypeId.GOAL_COUNT) {
      correctStatus = userRow.includes(drawResult.outcome[0][0]) ? MatchCorrectStatus.CORRECT : isPossibleWinStatus({
        playerOutcomes: userRow,
        currentOutcome: drawResult.outcome[0][0]
      });
    } else {
      correctStatus = MatchCorrectStatus.POSSIBLE_WIN;
    }
  }
  return correctStatus;
};
setGlobal('svs.components.tipsen.hooksWager.useWagerCurrentCorrectValues', useWagerCurrentCorrectValues);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-get-draw-state.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-get-draw-state.js');
"use strict";

const {
  selectDraw
} = svs.components.sport.drawsRedux.selectors;
const {
  getDrawStateEnum
} = svs.components.sport.tipsenShared;
const {
  useSelector
} = ReactRedux;
const useGetDrawState = (productId, drawNumber) => {
  const draw = useSelector(state => selectDraw(state, productId, drawNumber));
  const drawStateId = draw === null || draw === void 0 ? void 0 : draw.drawStateId;
  const drawStateEnum = getDrawStateEnum(drawStateId);
  return drawStateEnum;
};
setGlobal('svs.components.tipsen.hooksWager.useGetDrawState', useGetDrawState);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-draw-error-handling.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-draw-error-handling.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  useGetCurrentWagerProductInfo
} = svs.components.tipsen.hooksWager;
const {
  useDrawResults
} = svs.components.tipsen.hooks;
const {
  useDialog
} = svs.components.lbUi.betterDialog;
const {
  useHistory
} = ReactRouterDOM;
const {
  useEffect,
  useCallback,
  useMemo
} = React;
const {
  selectDrawsRequests
} = svs.components.sport.drawsRedux.selectors;
const {
  useSelector
} = ReactRedux;
const {
  FetchState
} = svs.components.sport.drawsRedux;
const useDrawErrorHandling = _ref => {
  let {
    drawNumber,
    productId,
    errorDestination,
    errorDialog,
    shouldRedirect = true
  } = _ref;
  const dialogObject = useMemo(() => _objectSpread({
    icon: 'exclamation-sign',
    title: 'Just nu kan vi inte visa något resultat för denna omgång.',
    acceptText: 'Tillbaka till lobbyn'
  }, errorDialog), [errorDialog]);
  const resultErrorDialog = useDialog(dialogObject);
  const history = useHistory();
  const drawsRequests = useSelector(selectDrawsRequests);
  const drawId = "".concat(productId, "_").concat(drawNumber);
  const drawLoadingState = drawsRequests[drawId];
  const goToErrorDestination = useCallback(() => {
    if (errorDestination && errorDestination.length > 0) {
      history.replace(errorDestination);
    } else if (shouldRedirect) {
      history.goBack();
    }
  }, [errorDestination, history, shouldRedirect]);
  const {
    error
  } = useDrawResults({
    productId,
    drawNumber
  }, {
    skip: !drawNumber || !productId
  });
  useEffect(() => {
    const errorDialog = async () => {
      await resultErrorDialog.open();
      goToErrorDestination();
      await resultErrorDialog.close();
    };
    if (error && Object.keys(error).length > 0 || drawLoadingState && drawLoadingState === FetchState.Rejected) {
      errorDialog();
    }
  }, [drawLoadingState, error, goToErrorDestination, history, resultErrorDialog]);
};
const useDrawErrorHandlingWagerId = _ref2 => {
  let {
    wagerId,
    errorDestination,
    errorDialog
  } = _ref2;
  const {
    productId,
    drawNumber
  } = useGetCurrentWagerProductInfo({
    wagerId
  });
  useDrawErrorHandling({
    drawNumber,
    productId,
    errorDestination,
    errorDialog
  });
};
setGlobal('svs.components.tipsen.hooksWager.useDrawErrorHandling', {
  useDrawErrorHandling,
  useDrawErrorHandlingWagerId
});

 })(window);
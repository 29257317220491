(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-observed-draw-result.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-observed-draw-result.js');
"use strict";

const {
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback
} = React;
const {
  log
} = svs.core;
const logger = log.getLogger('tipsen:hooksWager:useObservedDrawResult');
const {
  nanoid
} = RTK;
const {
  useDrawResultFromCurrentWagerDraw
} = svs.components.tipsen.hooksWager;

const createObserverOrchestrator = _ref => {
  let {
    onUpdate,
    immediate = true
  } = _ref;
  let observers = [];
  const lateObservers = [];
  let latestBroadcastedEvent = null;
  let isRunning = false;
  const callbackMap = {};
  const queue = [];
  const updateCurrent = event => {
    onUpdate(event);
    latestBroadcastedEvent = event;
  };
  const runObservers = function (_observers, event) {
    let callback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
    for (const observer of _observers) {
      observer.callback(event, callback);
    }
  };
  const unobserve = _ref2 => {
    var _callbackMap$uuid;
    let {
      observer: fn,
      uuid
    } = _ref2;
    (_callbackMap$uuid = callbackMap[uuid]) === null || _callbackMap$uuid === void 0 || _callbackMap$uuid.call(callbackMap);
    observers = observers.filter(observer => observer.callback !== fn);
    delete callbackMap[uuid];
  };
  const observe = observer => {
    const uuid = nanoid();
    const newObserver = {
      uuid,
      callback: observer
    };
    observers.push(newObserver);

    if (isRunning) {
      lateObservers.push(newObserver);
    }
    if (!isRunning && latestBroadcastedEvent) {
      observer(latestBroadcastedEvent);
    }
    return () => unobserve({
      observer,
      uuid
    });
  };
  const run = () => {
    isRunning = true;
    const nextEvent = queue.shift();
    const observersCount = observers.length;
    const readyObservers = new Set();
    const onReadyCallback = _ref3 => {
      let {
        uuid
      } = _ref3;
      return () => {
        if (readyObservers.has(uuid)) {
          return;
        }
        readyObservers.add(uuid);
        if (readyObservers.size === observersCount) {
          updateCurrent(nextEvent);
          runObservers(lateObservers, nextEvent);
          lateObservers.length = 0;
          if (queue.length) {
            run();
          } else {
            isRunning = false;
          }
        }
      };
    };
    for (const observer of observers) {
      const onReady = onReadyCallback(observer);
      callbackMap[observer.uuid] = onReady;
      observer.callback(nextEvent, onReady);
    }
  };
  const broadcast = (event, forceReset) => {
    const observersCount = observers.length;
    if (immediate || !observersCount) {
      runObservers(observers, event);
      updateCurrent(event);
      return;
    }
    if (forceReset) {
      logger.debug("forced reset on orchestrator queue");
      updateCurrent([]);
      queue.length = 0;
    }
    queue.push(event);
    if (!isRunning) {
      run();
    }
  };
  return {
    observe,
    unobserve,
    broadcast
  };
};
const ObservedDrawResultContext = React.createContext();

const ObservedDrawResultProvider = _ref4 => {
  let {
    children,
    wagerId
  } = _ref4;
  const [currentValue, setCurrentValue] = useState([]);
  const previousDrawNumber = useRef();
  const onUpdate = useCallback(event => {
    setCurrentValue(event);
  }, []);
  const observerOrchestrator = useRef(createObserverOrchestrator({
    onUpdate,
    immediate: false
  }));

  const [result, drawNumber] = useDrawResultFromCurrentWagerDraw({
    wagerId
  });


  const isError = Boolean(result.error);
  useEffect(() => {
    if (!previousDrawNumber.current) {
      previousDrawNumber.current = drawNumber;
    }
    const hasChangedDrawNumber = previousDrawNumber.current && drawNumber !== previousDrawNumber.current;
    const hasData = result.data.some(v => Array.isArray(v) ? v.length > 0 : v);
    if (!hasData && !isError && !hasChangedDrawNumber) {
      return;
    }
    if (hasChangedDrawNumber && hasData) {
      previousDrawNumber.current = drawNumber;
    }
    observerOrchestrator.current.broadcast(result.data, hasChangedDrawNumber);
  }, [result.data, drawNumber, isError]);
  const contextValue = useMemo(() => ({
    observe: observerOrchestrator.current.observe,
    current: currentValue
  }), [currentValue]);
  return React.createElement(ObservedDrawResultContext.Provider, {
    value: contextValue
  }, children);
};

const useObservedDrawResult = () => {
  const observedContext = useContext(ObservedDrawResultContext);
  if (!observedContext) {
    throw new Error('useObservedDrawResult could not be used without a mounted ObservedDrawResultContext.Provider');
  }
  return observedContext;
};
setGlobal('svs.components.tipsen.hooksWager', {
  ObservedDrawResultProvider,
  useObservedDrawResult,
  createObserverOrchestrator
});

 })(window);
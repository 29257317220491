(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-draw-result-from-current-wager-draw.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-draw-result-from-current-wager-draw.js');
"use strict";

const {
  useMemo
} = React;
const {
  useGetCurrentWagerProductInfo
} = svs.components.tipsen.hooksWager;
const {
  useDrawResults
} = svs.components.tipsen.hooks;
const useDrawResultFromCurrentWagerDraw = _ref => {
  let {
    wagerId
  } = _ref;
  const {
    productId,
    drawNumber
  } = useGetCurrentWagerProductInfo({
    wagerId
  });
  const result = useDrawResults({
    productId,
    drawNumber
  });
  return useMemo(() => [result, drawNumber], [result, drawNumber]);
};
setGlobal('svs.components.tipsen.hooksWager.useDrawResultFromCurrentWagerDraw', useDrawResultFromCurrentWagerDraw);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-get-wager-draw.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-get-wager-draw.js');
"use strict";

const {
  useLocation
} = ReactRouterDOM;
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  useState,
  useEffect
} = React;
const {
  hooks: {
    useDraw
  },
  selectors: {
    selectDrawsRequests
  },
  FetchState
} = svs.components.sport.drawsRedux;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  useMemo
} = React;
const {
  useSelector
} = ReactRedux;
const {
  DrawState
} = svs.components.sport.tipsenShared;
const useGetDrawFromQueryString = () => {
  const {
    search
  } = useLocation();
  const qs = new URLSearchParams(search);
  const draw = parseInt(qs.get('draw'), 10);
  const currentDraw = parseInt(qs.get('currentDraw'), 10);
  return useMemo(() => {
    const drawNumbers = [];
    if (draw) {
      drawNumbers.push(draw);
    }
    if (currentDraw) {
      drawNumbers.push(currentDraw);
    }
    return drawNumbers;
  }, [draw, currentDraw]);
};
const defaultReturn = [];
const useGetWagerDraw = _ref => {
  var _wager$productBet;
  let {
    wagerId
  } = _ref;
  const {
    data: wager
  } = tipsenApi.useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const {
    drawNumber,
    completedDrawNumbers,
    lastCompletedDrawNumber,
    totalDrawCount,
    productId
  } = (_wager$productBet = wager === null || wager === void 0 ? void 0 : wager.productBet) !== null && _wager$productBet !== void 0 ? _wager$productBet : {};
  const hasMultiDraws = totalDrawCount > 1;
  const qsDrawNumbers = useGetDrawFromQueryString();
  const [activeDrawNumber, setActiveDrawNumber] = useState();
  const definition = useDefinition();
  const shouldFetchJackpot = (definition === null || definition === void 0 ? void 0 : definition.hasJackpot) || false;
  const draw = useDraw({
    productId,
    drawNumber: activeDrawNumber
  }, {
    shouldFetchJackpot
  });
  const drawRequests = useSelector(selectDrawsRequests);
  const drawId = "".concat(productId, "_").concat(activeDrawNumber);
  const drawNumbersArray = useMemo(() => {
    if (completedDrawNumbers) {
      return completedDrawNumbers.slice().concat(drawNumber).concat(lastCompletedDrawNumber ? lastCompletedDrawNumber : []).sort((a, b) => a - b);
    }
    return defaultReturn;
  }, [completedDrawNumbers, lastCompletedDrawNumber, drawNumber]);
  const isAllRoundsCompleted = wager ? wager.productBet.completedDrawCount === wager.productBet.totalDrawCount : false;
  const validatedDrawNumbersArray = useMemo(() => {
    if (!hasMultiDraws) {
      return drawNumbersArray;
    }
    const [selectedDrawNumber] = qsDrawNumbers;
    const [firstDrawNumber] = drawNumbersArray;
    const drawOutsideCompletedDrawNumbers = isAllRoundsCompleted && !drawNumbersArray.includes(selectedDrawNumber);
    if (qsDrawNumbers.length > 0 && selectedDrawNumber >= firstDrawNumber && !drawOutsideCompletedDrawNumbers && drawNumbersArray.length) {
      return [...drawNumbersArray, ...qsDrawNumbers];
    }
    return drawNumbersArray;
  }, [drawNumbersArray, qsDrawNumbers, isAllRoundsCompleted, hasMultiDraws]);
  const [selectedDrawNumber] = qsDrawNumbers;
  const isSelectedDrawNumberValid = validatedDrawNumbersArray.length > drawNumbersArray.length;
  const lastValidatedDrawNumber = selectedDrawNumber || validatedDrawNumbersArray.slice().pop();
  const lastCompletedDrawNumberFromDraws = drawNumbersArray.slice().pop();
  const [firstDrawNumber] = drawNumbersArray;
  const isWagerLoaded = Boolean(wager);
  useEffect(() => {
    if (isSelectedDrawNumberValid && lastValidatedDrawNumber && selectedDrawNumber) {
      setActiveDrawNumber(selectedDrawNumber);
      return;
    }
    if (firstDrawNumber) {
      setActiveDrawNumber(firstDrawNumber);
    }
  }, [lastValidatedDrawNumber, isSelectedDrawNumberValid, firstDrawNumber, selectedDrawNumber]);
  const drawLoadingState = drawRequests[drawId];
  return useMemo(() => {
    var _ref2;
    const allDrawNumbers = new Set(validatedDrawNumbersArray);
    const activeDrawNumberIndex = (_ref2 = [...allDrawNumbers]) === null || _ref2 === void 0 ? void 0 : _ref2.findIndex(drawNumber => drawNumber === activeDrawNumber);
    if (hasMultiDraws && drawLoadingState && drawLoadingState !== FetchState.Pending) {
      if ((draw === null || draw === void 0 ? void 0 : draw.drawStateId) < DrawState.Opened) {
        setActiveDrawNumber(lastCompletedDrawNumberFromDraws);
      } else if (drawLoadingState === FetchState.Rejected && isWagerLoaded && qsDrawNumbers[0] > lastCompletedDrawNumberFromDraws + 1) {
        allDrawNumbers.delete(qsDrawNumbers[0]);
      } else if (drawLoadingState === FetchState.Rejected && isWagerLoaded) {
        allDrawNumbers.delete(lastCompletedDrawNumberFromDraws + 1);
      }
    }
    return {
      activeDrawNumber,
      allDrawNumbers,
      draw,
      activeDrawNumberIndex
    };
  }, [activeDrawNumber, draw, validatedDrawNumbersArray, drawLoadingState, hasMultiDraws, isWagerLoaded, lastCompletedDrawNumberFromDraws, qsDrawNumbers]);
};
setGlobal('svs.components.tipsen.hooksWager.useGetWagerDraw', useGetWagerDraw);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-get-current-wager-product-info.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-get-current-wager-product-info.js');
"use strict";

const {
  useMemo
} = React;
const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  hooksWager
} = svs.components.tipsen;
const useGetCurrentWagerProductInfo = _ref => {
  var _playerWager$data;
  let {
    wagerId
  } = _ref;
  const {
    activeDrawNumber
  } = hooksWager.useGetWagerDraw({
    wagerId
  });
  const playerWager = tipsenApi.useGetPlayerWagerQuery({
    wagerId
  }, {
    skip: !wagerId
  });
  const productId = (_playerWager$data = playerWager.data) === null || _playerWager$data === void 0 || (_playerWager$data = _playerWager$data.productBet) === null || _playerWager$data === void 0 ? void 0 : _playerWager$data.productId;
  return useMemo(() => ({
    drawNumber: activeDrawNumber,
    productId
  }), [activeDrawNumber, productId]);
};
setGlobal('svs.components.tipsen.hooksWager.useGetCurrentWagerProductInfo', useGetCurrentWagerProductInfo);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-sanitize-wager.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-sanitize-wager.js');
"use strict";

const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  useHistory
} = ReactRouterDOM;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  useMemo
} = React;
const {
  useDialog
} = svs.components.lbUi.betterDialog;
const secondsPerDay = 86400;
const halfAYearInDays = 180;
const useIsDateToOld = date => useMemo(() => {
  const secondsSince = Math.floor((new Date() - new Date(date)) / 1000);
  const interval = secondsSince / secondsPerDay;
  return Boolean(date && interval >= halfAYearInDays);
}, [date]);
const useSanitizeWager = wagerId => {
  const wagerToOldDialog = useDialog({
    title: 'Detaljer för spel äldre än 6 månader kan tyvärr inte visas.',
    icon: 'exclamation-sign',
    acceptText: 'Tillbaka till lobbyn'
  });
  const history = useHistory();
  const definition = useDefinition();
  const wager = tipsenApi.useGetPlayerWagerQuery({
    wagerId
  });
  const registerWagerTime = wager.isSuccess ? wager.data.productBet.regWagerTime : undefined;
  const isToOldGame = useIsDateToOld(registerWagerTime);
  const productId = wager.isSuccess ? wager.data.productBet.productId : undefined;
  const definitionDrawProductIds = definition.drawProductIds;
  return useMemo(() => {
    const wagerToOld = async () => {
      await wagerToOldDialog.open();
      wagerToOldDialog.close();
      history.replace("/".concat(TipsenModuleGameUrl.MY_BETS));
    };
    if (wager.isSuccess && !definitionDrawProductIds.includes(productId)) {
      history.replace("/".concat(TipsenModuleGameUrl.MY_BETS));
    } else if (wager.isSuccess && isToOldGame) {
      wagerToOld();
    } else if (wager.isSuccess && !isToOldGame) {
      return true;
    }
    return false;
  }, [definitionDrawProductIds, history, isToOldGame, productId, wager.isSuccess, wagerToOldDialog]);
};
setGlobal('svs.components.tipsen.hooksWager.useSanitizeWager', useSanitizeWager);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/hooks-wager/assets/javascripts/use-force-get-wager.js') >= 0) return;  svs.modules.push('/components/tipsen/hooks-wager/assets/javascripts/use-force-get-wager.js');
"use strict";

const {
  tipsenApi
} = svs.components.tipsen.store;
const {
  useGetCurrentWagerProductInfo,
  useGetDrawState
} = svs.components.tipsen.hooksWager;
const {
  usePrevious
} = svs.components.lbUtils.usePrevious;
const {
  drawStateEnums
} = svs.components.sport.tipsenShared;
const {
  useEffect
} = React;
const MAX_DELAY = 10000;
const MIN_DELAY = 7000;
const useForceGetWager = _ref => {
  let {
    wagerId
  } = _ref;
  let timeout;
  const {
    productId,
    drawNumber
  } = useGetCurrentWagerProductInfo({
    wagerId
  });
  const drawState = useGetDrawState(productId, drawNumber);
  const previousDrawState = usePrevious(drawState);
  const [invalidatePlayerWager] = tipsenApi.useInvalidatePlayerWagerTagMutation();
  useEffect(() => {
    if (drawState === drawStateEnums.FINALIZED && previousDrawState !== drawStateEnums.FINALIZED) {
      timeout = setTimeout(() => {
        invalidatePlayerWager({
          wagerId
        });
      }, Math.round(Math.random() * (MAX_DELAY - MIN_DELAY + 1) + MIN_DELAY));
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, [drawState]);
};
setGlobal('svs.components.tipsen.hooksWager.useForceGetWager', useForceGetWager);

 })(window);